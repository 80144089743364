.App_header {
    background-color: var(--theme_app_header_color);    
               
          
    z-index: 1000;

    left: 0px;

    right: 0px;

    top: 0px;                          
  position: fixed;
  width: 100%;
    display: flow-root;                           
    padding: 2px 20px;                           
    box-sizing: border-box;   
         
    user-select: none;          
    -webkit-user-select: none;
    -moz-user-select: none;      
    -ms-user-select: none;        
   
}
.phone-login-popup-overlay {
    max-width: 100%;
    position: fixed;  /* Use `fixed` for better consistency on scroll */
    z-index: 1011;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    right: 0;
    bottom: 0;
    top: 0; 
    height: calc(100% - 0px);
   
    transition: opacity 0.3s ease, visibility 0.3s ease;  /* Smooth transition */
}
.phone-login-popup-overlay .popup-box {
    background-color: var(--body_background);
    border-radius: 10px;
    padding: 5px;               /* Added more padding for better spacing */
    max-width: 350px;
    width: 100%;
    margin:50px auto;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);  /* Smooth shadow */
    transition: all 0.3s ease;   /* Smooth transition */
    border-top: solid 5px var(--theme_color);
 
}
.phone-login-popup-overlay .popup-box .phone-close-button {
    border-radius: 5px;
    padding: 10px 10px;
    background-color: var(--layout_background);
    color: #000;
    border: 1px solid var(--border_color);
    border: none;
    cursor: pointer;
    margin: 10px 10px;
    transition: 0.3s;
    font-weight: 600;
    font-size: var(--font_size_title);
}
.App_header .left_div
{
    float: left;
    width: 300px;
    
    height: 50px; 
    
    position: relative;
}
.App_header .left_div .menu-btn
{
    float: left;
    display: none;
    width: 30px;
    cursor: pointer;
    margin: 8px 0px 8px 0px;
    margin-right: 20px;
    height: 30px;
}
.App_header .left_div .menu-btn:hover
{
    color: var(--theme_color);
}
 
.App_header .left_div .logo
{
    width: 90px;
    height: 50px;
    float: left;
    object-fit: contain;
    cursor: pointer;
    opacity: 0.9;
}
.App_header .center_body
{
    
    height: 45px;
    float: left;
    width: calc(100%  - 600px);
  position: relative;
  margin:2px 1px 2px 10px;
   
  align-items: center;
}
 .App_header .center_body .menu_body {
    width: 500px;
    height: 45px;
   min-width: 400px;  
   margin:1px auto;
    align-items: center; 
    border-radius: 30px;
    display: flow-root;
  
     
}  
.App_header .design_btn {
    text-decoration: none;
    float: left;
    display:flow-root;
    font-weight:400;
    padding: 7px 10px;
    margin: 5px 2px 5px 20px; 
    cursor: pointer;
    font-size: var(--font_size_button);
    position: relative;
    transition: color 0.5s ease;
 background-color: var(--layout_background);
  border: solid 1px var(--border_color);
color: var(--text_color);
 border-radius: 30px;
}
.App_header .design_btn:hover{
    -webkit-animation: pulse 1s ease-out;
}
.App_header .design_btn .button-text {
    white-space: nowrap;        /* Prevents text wrapping */
    text-align: center;         /* Center-align text */
    line-height: 24px;
    float: right;
     
  }
  .App_header .design_btn .button-icon
{
    width: 22px;
    height: 22px; 
    float: left;
}

.App_header .center_body .menu_body .link_btn {
    text-decoration: none;
    float: left;
   line-height: 30px;
    font-weight:400;
    padding: 3px 15px;
    margin: 5px 15px 5px 15px; 
    cursor: pointer;
    font-size: var(--font_size_button);
    position: relative;
    transition: color 0.5s ease;
 
color: var(--text_color);
}
.App_header .center_body .link_btn:hover {
    transition: width 0.5s ease;     
 border-bottom: solid 2px var(--theme_color) ;
 
}


/* Input field inside search */
.App_header .center_body .search .src_input {
    width: calc(100% - 75px);
    height: calc(100% - 7px);
    margin: auto;
    outline: none;
    padding: 0;
    border-radius: 20px;
    margin: 2px 10px;
    background-color: var(--layout_background);
    font-size: var(--font_size_title);
    border: none;
    text-transform: capitalize;
    float: left;
}

/* Change .search border color when .src_input is focused */
.App_header .center_body .search:focus-within {
    border: solid 2px var(--theme_color);   /* Border color on focus */
    margin:0px 0px 0px 0px;

}

.App_header .center_body .search .search_img
{
float: right;
height: 30px;
width: 30px;
 object-fit: contain;
margin: 7px 10px 7px 0px; 
cursor: pointer;
filter: var(--icon-color);
opacity: 0.8;
}
 
.App_header .right_div
{
     
    height: 50px;
    float: right;
  display:flex;
 
    position: relative;
 
}
 
.App_header .right_div .login_settings
{
float: left;
height: 50px;
width: 50px;
 object-fit: contain;
margin: 0px 0px 0px 30px; 
background-color: #fff;
border: solid 1px var(--border_color);
cursor: pointer;
border-radius: 50%;
}
.App_header .right_div .download-icon
{
    display: block;
    float: left;
    
}
.App_header .right_div .login-icon
{
    display: inline-flex;
    align-items: center;         /* Center-align image and text vertically */
    justify-content: center;     /* Center-align horizontally */
    gap: 5px;                   /* Space between text and image */
    
    background: var(--theme_blue_color, var(--theme_color));
    color: var(--text_color);
    text-decoration: none;
    padding: 0px 5px 0px 20px; 
    margin: 5px 5px;
    font-size: var(--font_size_button);
    border-radius: 30px;
    height: 40px;
    transition: transform 0.3s ease;
    cursor: pointer;
    line-height: 24px;
    float: right;
}
/* Styling the scrollbar */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

/* Scroll to top button */
.scroll-to-top-btn {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    background-color: var(--layout_background); 
     text-align: center;
    border: none;
 
    border-radius: 50%; 
    cursor: pointer;
    color: var(--icon-color);
    box-shadow: 0 4px 10px rgba(0,0,0,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s, transform 0.3s;
    z-index: 999;
}

.scroll-to-top-btn:hover {
    
    transform: scale(1.1);
    color: #fff;
    background-color: var(--theme_color);
}

.scroll-to-top-btn:active {
    transform: scale(0.95);
}
 
@media only screen and (max-width: 600px) {
    .App_header .center_body
    {
        display: none;
    }
    .App_header {
        background-color: var(--body_background);    
        color: var(--text_color);                  
            
        z-index: 1000;

        left: 0px;
    
        right: 0px;
    
        top: 0px;                                
      position: fixed;
      width: 100%;
        display: flow-root;                           
        padding:0px 10px;                           
        box-sizing: border-box;   
        -webkit-box-shadow: 0px 5px 5px -4px rgba(0,0,0,0.14);
        -moz-box-shadow: 0px 5px 5px -4px rgba(0,0,0,0.14);
        box-shadow: 0px 5px 5px -4px rgba(0,0,0,0.14);       
    }
    .App_header .left_div
    {
        float: left;
        width: 200px;
        
        height: 50px; 
        
        position: relative;
    }
    .App_header .left_div .menu-btn
    {
        float: left;
        display: block;
        width: 30px;
        cursor: pointer;
        margin: 8px 0px 8px 0px;
        margin-right: 20px;
        height: 30px;
    }
    .App_header .left_div .menu-btn:hover
    {
        color: var(--theme_color);
    }
     
    .App_header .left_div .logo
    {
        width: 80px;
        height: 50px;
        float: left;
        object-fit: contain;
        cursor: pointer;
        opacity: 0.9;
    }
    .App_header .design_btn {
        text-decoration: none;
        float: left;
        display:flow-root;
        font-weight:400;
        padding: 5px 0px;
        margin: 7px 0px 7px 15px; 
        cursor: pointer;
        font-size: var(--font_size_button);
        position: relative;
        transition: color 0.5s ease;
     background-color: transparent;
     border-radius: 25px;
    color: var(--text_color);
     
    }
    .App_header .design_btn .button-text {
        white-space: nowrap;        /* Prevents text wrapping */
        text-align: center;         /* Center-align text */
        line-height: 27px;
        float: right;
        display: none;
      }
      .App_header .design_btn
    {
        width: 25px;
        height: 25px; 
        display: none;
    }
    .App_header .right_div
{
    width: 130px;
    height: 50px;
    float: right;
  align-items: flex-end;
    position: relative;    
    display: flow-root; 
}

.App_header .right_div .login_settings
{
float: right;
height: 40px;
width: 40px;
 
margin: 5px 0px 5px 0px; 
cursor: pointer;
 
}
.App_header .right_div .download-icon
{
    display: none;
}
  }


