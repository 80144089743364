.settings_root {
    max-width: 1000px;
    margin: 50px auto;
    padding: 20px;
    min-height: 500px;
    display: block; /* Changed from flow-root to block */
    text-align: left;
    background-color: var(--background_color, #fff); /* Added a fallback */
    border-radius: 8px; /* Optional: Adds a smoother UI */
}

.settings_root .header-text {
    font-size: var(--font_size_bg, 24px); /* Added fallback */
    font-weight: 400;
    clear: both;
    color: var(--text_color, #333); /* Added text color */
}


.settings_root .table_settings {
    display: flex;
 
    justify-content: space-between; /* Pushes elements apart */
    border-radius: 5px;
    padding: 10px;
    margin: 5px 0;
    border: solid 1px var(--border_color);
}
.settings_root .Profile_settings {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #fff;
    border: solid 1px var(--border_color);
    object-fit: cover;
    margin-left: auto; /* Pushes it to the right */
}
.settings_root .title-text,
.settings_root .name_tabel {
    width: 100%; /* Ensures both elements take full width */
    max-width: 500px; /* Adjust this value as per your layout */
}

.settings_root .name_tabel {
    display: block;
    text-align: left;
    font-weight: bold;
    color: var(--text_color, #333);
}