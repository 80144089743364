 
.scan_ad .tag_div .buy-now-btn:hover{
  -webkit-animation: pulse 1s infinite;
  background-color: var(--theme_lite_color);
}
 
.scan_ad .show_body
{
  position: relative;  /* Set the container as the reference point */
  width: 100%;
  height:calc(100vh - 130px);
  display: flex;
  justify-content: center;
  align-items: center; 
  overflow: hidden; 
}
.scan_ad .show_body .show_ads_img {
  position: absolute; 
  width:calc(100% - 40%); 
  object-fit: contain; 
  margin: 5px;
 
}
.animated-box {
    max-width: 100%;
     min-height: 100px; 
     display: flex;
     justify-content: center;
     align-items: center;
     margin: 20px;
     border-radius: 10px;       
     transition: all 0.8s ease-in-out;
}

 .title-div {
  max-width: 100%;
  margin-top: 80px;
  text-align: center;
  clear: both;
  margin-bottom: 20px;
}
.title-div .title-text {
  font-weight: 400;
  color: var(--theme_color);
  font-size: var(--font_size_bg);

}
.title-div .desc-text {
  font-weight: 400;
  text-align: center; 
  font-size: var(--font_size_title);
  
}

.title-div .info-text {
  font-weight: 300;
  margin-bottom: 20px;
  font-size: 12px;

}
.photo_div {
  max-width: 100%;
  padding: 5px;
  display: flow-root;
  align-items: center;

}

.photo_div .photo_body {
  width: 300px;
  margin: auto;
  align-items: center;
  position: relative;
}

.photo_div .photo_img {
 
  width: 300px;
  height: 300px;
  background-color: #Fff;
  border-radius: 100%;
  padding: 10px;
  align-items: center;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

/* Individual Cards */
.card_view {
  width: calc(100%/3 - 70px);
  min-height: 300px;
 background-color: var(--theme_app_header_color);
  border-radius: 10px;
  padding: 20px;
  text-align: left;
  line-height: 25px;
  box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.card_view:hover {
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
}
.card_img {
  width: 50%;
  height: 100px;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 12px;
  margin-bottom: 20px;
}
.card_pro_img {
  width: 100%;
  height: 200px;
  object-fit: contain;
  text-align: left;
  margin-bottom: 20px;
}
.title_text {
  font-size: var(--font_size_title);
  text-align: left;
  font-weight: 600;
}

.desc_text {
  font-size: var(--font_size_normal);
  text-align: left;
}
.Ourfounders_card_view
{
max-width: 300px;
min-height: 300px;
}
.Ourfounders_img
{
  width: 300px;
  height: 300px; 
 object-fit: cover;
  background-color: #Fff;
  border-radius: 100%;
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media (max-width: 770px) {
  .card_view {
    width: calc(50% - 20px);
  }
  .show-case-info-body
{
max-width: 900px;
  margin: auto;
  padding: 30px 0px;
}
}
.show-case-info-body
{

  margin: auto;
  padding: 30px 0px;
}
 
.show-case-prodcut-body
{
  
  padding: 30px 0px;
}
.block_div

{  border-radius: 20px; 

   max-width: 100%;  

   height:calc(100vh - 100px);

   background-color: var(--theme_color);

   padding: 10px 10px;  
margin:0px 10px;
  
position: relative;
}
.scan_ad {
 

  max-width: 90%;

  margin: auto;

  position: relative;

  min-height: 100px;

  padding: 20px 30px;

  clear: both;
 

}
.scan_ad .tag_div {


position: absolute;
  float: left; 
  width: calc(100% - 100px);
text-align: left;
  display: flow-root;
z-index: 2;
  min-height: 200px; 
margin: 20px 100px;
}
.scan_ad .tag_div .tag_line {

  font-size:var(--font_size_bg);

  line-height: 66px;

  font-weight: 600;

}
.scan_ad .tag_div .tag_line2 {


  font-size: var(--font_size_header);
  font-weight: 500;
 

  margin: 5px 0px;

}
 
.scan_ad .tag_div .buy-now-btn {

  min-height: 40px;

 

  object-fit: contain;

  text-align: center;

  line-height: 40px;

  background-position: right 10px center;

  background-repeat: no-repeat;

 

  border-radius: 50px;

  margin: 10px 0px;

  font-size: var(--font_size_title);

  background-image: url(../assets/start_48dp_000000_FILL0_wght400_GRAD0_opsz48.png);

  padding: 5px 40px 5px 20px;

  background-size: 24px;

  font-weight: 600;

  float: left;
 
color: var(--text_color);
  text-decoration: none;
  background-color: #fff;
  cursor: pointer;  

}
.scan_ad .tag_div .buy-now-btn:hover{
  -webkit-animation: pulse 1s infinite;
  background-color: var(--theme_lite_color);
}
 
.scan_ad .show_body
{
  position: relative;  /* Set the container as the reference point */
  width: 100%;
  height:calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center; 
  overflow: hidden; 
}
.scan_ad .show_body .show_ads_img {
  position: absolute; 
  width:calc(100% - 40%); 
  object-fit: contain; 
  margin: 5px;
 
}

@media (max-width: 600px) {


  .block_div

  {  border-radius: 20px; 
  
     max-width: 100%;  
  
     height:calc(100vh - 100px);
  
     background-color: var(--theme_color);
  
     padding: 10px 10px;  
  margin:0px 10px;
      color: #000;
  position: relative;
  }
  .scan_ad {
   
  
    max-width: 90%;
  
    margin: auto;
  
    position: relative;
  
    min-height: 200px;
  
    padding: 20px 30px;
  
    clear: both;
   
  
  }
  .scan_ad .tag_div {
  
  
  position: absolute;
    float: left; 
    width: calc(100% - 10px);
  text-align: left;
    display: flow-root;
  z-index: 2;
    min-height: 100px; 
  margin: 20px 0px;
  }
  .scan_ad .tag_div .tag_line {
  
    font-size:var(--font_size_bgm);
  
  
  
    font-weight: 600;
  
  }
  .scan_ad .tag_div .tag_line2 {
  
    font-size:var(--font_size_title);
  
    font-weight: 500; 
  
    margin: 5px 0px;
  
  }
   
  .scan_ad .tag_div .buy-now-btn {
  
    min-height: 40px;
  
   
  
    object-fit: contain;
  
    text-align: center;
  
    line-height: 40px;
  
    background-position: right 10px center;
  
    background-repeat: no-repeat;
  
   
  
    border-radius: 50px;
  
    margin: 10px 0px;
  
    font-size: var(--font_size_title);
  
    background-image: url(../assets/start_48dp_000000_FILL0_wght400_GRAD0_opsz48.png);
  
    padding: 5px 40px 5px 20px;
  
    background-size: 24px;
  
    font-weight: 600;
  
    float: left;
   
  color: var(--text_color);
    text-decoration: none;
    background-color: #fff;
    cursor: pointer;  
  
  }
  .scan_ad .tag_div .buy-now-btn:hover{
    -webkit-animation: pulse 1s infinite;
    background-color: var(--theme_lite_color);
  }
   
  .scan_ad .show_body
  {
    position: relative;  /* Set the container as the reference point */
    width: 100%;
    height:calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center; 
    overflow: hidden; 
  }
  .scan_ad .show_body .show_ads_img {
    position: absolute; 
    width:calc(100% - 10%); 
    object-fit: contain; 
    margin: 5px;
   
  }

  .card_view {
    width: 100%;
    min-height: auto;
  }

  .title_text {
    font-size: 26px;
  }

  .desc_text {
    font-size: 16px;
  }


}