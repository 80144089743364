.tag_div {
  
    text-align: left;
      display: flow-root;
    z-index: 2;
     align-items: center;
     max-width: 600px;
   padding: 2px;
    }
.tag_div .tag_line {

    font-size: var(--font_size_header);
 
    line-height:20px;
  
    font-weight: 600;
  
  }
.tag_div .tag_line2 {
  
    font-size: var(--font_size_normal);
  
    font-weight: 400;

    line-height: 15px;
  
    margin: 10px 0px;
  
  }
.tab-content-text { 
    font-size: var(--font_size_normal);
    text-align: left;
 
    float: left;
    width: calc(100% - 400px);
  }
  .tab-content-text .input-div
  {
    min-height: 10px;
 
    margin: 10px 0px;
    
    padding: 2px;
  }
  .tab-content-text .input-div .input_textbox
  { 
outline: none;
font-size: var(--font_size_title);
border: solid 2px var(--border_color);
  }

  .qrcode-content-text { 
    font-size: var(--font_size_normal);
    text-align: left;
    padding: 0px;
    float: right;
 display: none;
    margin: 5px;
    padding: 5px;
    background-color: var(--border_color);
    border-radius: 5px;
  
  }
  .qrcode-content-text .qrcode-content { 
    font-size: var(--font_size_normal);  
    padding: 5px;     
    min-height: 100px; 
    border-radius: 5px;
    background-color: var(--border_color);
    border-radius: 5px;
    position: relative;
    display: inline-block;
    background-color: #fff;
  }
  .qrcode-content-text .qrcode-content .logo_img
  { border-radius: 5px; 
    background-color: #fff;
    padding: 5px;
    position:absolute; 
     margin: auto;   
    width: 40px;
    height: 40px;
  }
    .qrcode-content-text .qrcodecanvas
  {
 
    background-color: #fff;
    padding: 10px;
  }
 
 
  .stock-info-text
  {    font-size: var(--font_size_normal);
    float: left;
    max-width:600px;
    text-align: left;
    margin: 5px 0px; 
line-height: 18px;
    font-weight: 400;
  }
  .stock-info-text .title-text
  {
font-size: var(--font_size_title);
font-weight: 600;
padding: 10px 10px;
 border-radius: 5px;
border:dotted 2px var(--border_color);
margin: 5px 0px;
  }
  .stock-info-text .header-text
  {
font-size: var(--font_size_header);
font-weight: 600;
margin: 5px 0px;
  }
  .stock-info-text .buy-btn
  {
    background-color: var(--layout_background);
    border-radius: 30px;
    max-width: 100px;
    text-align: center;
    font-size: var(--font_size_button);
    cursor: pointer;
    font-weight: 600;
    margin: 10px 0px;
    padding: 10px 20px;
  }  .stock-info-text .buy-btn:hover{
    -webkit-animation: pulse 1s ease-out;
  } 
  .stock-info-text .price-text
  {
font-size: var(--font_size_header);
font-weight: 600;  
margin: 10px 0px;
  }
  @media (max-width: 600px) {

    .tag_div {
 background-color: var(--layout_background);
        margin: auto;
           width: calc(100% - 20px);
         text-align: left;

           display: flow-root;
         z-index: 2;
          align-items: center;
          max-width: 600px;
          clear: both;
        padding: 5px 10px;
         }
       .tag_div .tag_line {
        
          font-size:var(--font_size_title);
        
        
        
          font-weight: 600;
        
        }
        .tag_div .tag_line2 {
        
          font-size:var(--font_size_normal);
        
          font-weight: 500; 
        
          margin: 5px 0px;
        
        }
   
          
         .container_design
         {
           
             
             min-height: 100px;     
             border-radius: 8px;  
             
         }
         .input-body
         {
             max-width: 100%;
             display: flow-root;
         }
         .tab-content-text { 
             font-size: var(--font_size_normal);
             text-align: left;
             padding: 10px 0px;
             float: none;
             
             clear: both;
             width: calc(100% - 10px);
             margin: auto;
           }
           .tab-content-text .input-div
           {
             min-height: 10px;
          
             margin: 10px 0px;
             
              
             padding: 2px;
           }
           .tab-content-text .input-div .input_textbox
           { 
         outline: none;
         font-size: var(--font_size_title);
         border: solid 2px var(--border_color);
           }
         
           .qrcode-content-text { 
             font-size: var(--font_size_normal);
             text-align: left;
             padding: 0px;
             float: none;
          display: none;
             margin: 5px auto;
             padding: 5px;
             width: 310px;
             background-color: var(--border_color);
             border-radius: 5px;
           
           }
           .qrcode-content-text .qrcode-content { 
             font-size: var(--font_size_normal);  
             padding: 5px;     
             min-height: 100px; 
         
             border-radius: 5px;
             background-color: var(--border_color);
             border-radius: 5px;
             position: relative;
             display: inline-block;
             background-color: #fff;
             margin: auto;
           }
           .qrcode-content-text .qrcode-content .logo_img
           { border-radius: 5px; 
             background-color: #fff;
             padding: 5px;
             position:absolute; 
              margin: auto;   
             width: 40px;
             height: 40px;
           }
             .qrcode-content-text .qrcodecanvas
           {
          
             background-color: #fff;
             padding: 10px;
           }
        
        
           .stock-info-text
           {    font-size: var(--font_size_normal);
             float: left;
             max-width:600px;
             text-align: left;
             margin:10px;
     
             cursor: pointer;
         line-height: 18px;
             font-weight: 400;
           }
           .stock-info-text .title-text
           {
         font-size: var(--font_size_normal);
         font-weight: 600;
         padding: 10px 10px;
          border-radius: 5px;
         border:dotted 2px var(--border_color);
         margin: 5px 0px;
           }
           .stock-info-text .header-text
           {
         font-size: var(--font_size_title);
         font-weight: 600;
         margin: 5px 0px;
           }
           .stock-info-text
           {    font-size: var(--font_size_normal);
             float: left;
             text-align: left;
             margin: 5px 5px;
             cursor: pointer;
             font-weight: 400;
           } 
           
  }